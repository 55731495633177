.TopNav {
    background-color: #ede5c5;
}

.contents {
    padding-left: 25px;
    padding-top: 18px;
    padding-bottom: 18px;
}

Link {
    color: white;
}
