.sidenav {
    height: 100%;
    width: 160px;
    color: black;
}

.sidenavul {
    background-color: #a3d0e3;
    list-style-type: none;
    height: 100%;
}

li {
    margin-bottom: 15px;
}

a {
    color: black;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration-color: aqua;
}

ul {
    margin: 0px;
}
