html, body, #app, #sidebyside, #sidenav, #contents {
  height: 100%
}

#sidenav {
  background-color: #a3d0e3;
}

#contents {
  background-color:#d7f9fa;
  width: 100%;
}

.rowC {
  display:flex; 
  flex-direction:row;
}

.contentComponents {
  margin: 30px;
}

#cmodal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  box-shadow: 10px 5px 5px rgb(0,0,0);
  width: 100%;
  height: 100%;
}

#inner {
  padding: 20px;
  background-color:azure;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  box-shadow: 10px 5px 5px rgb(0,0,0);
  width: 175;
  height: 140px;
}

#buttonwrap {

  padding: 10px;
  margin: 10px;
  .mbtns {
    margin-right: 10px;    
  }

}


table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}
